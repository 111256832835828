@font-face {
    font-family: 'Montserrat-Light';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/Montserrat-Light.otf') format('opentype');
}

@font-face {
    font-family: 'NovelPro-Regular';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/NovelPro-Regular.otf') format('opentype');
}

.app {
    display: flex;
    position: relative;
    min-height: 720px;
    max-width: 1960px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
